import React, { useContext } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { QrRenewal } from "../../api/QR List All Api/QrListAllApi";
import { Zoom, toast } from "react-toastify";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const QrRenewDateModal = (props) => {
  const { getData } = props;
  const { renewDateModal, setRenewDateModal, setQrId, qrId } =
    useContext(QrListAllContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  const handleEmpty = () => {
    setQrId("");
    setRenewDateModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newData = {
        isExpireDateCompare: true,
      };
      const { data } = await QrRenewal({ formData: newData, qrId });
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        handleEmpty();
        getData();
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      handleEmpty();
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <Modal size="md" isOpen={renewDateModal} toggle={() => handleEmpty()}>
      <ModalHeader toggle={() => handleEmpty()}>
        {"Renew Date Plan"}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col md="12 ">
              <p className="text-center">Are you sure? do you want to renew?</p>
            </Col>
            <Col className="d-flex justify-content-center ">
              <Button type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default QrRenewDateModal;
